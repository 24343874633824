
// PRODUCTION
const API = `https://us-central1-auth-testing-b4cbc.cloudfunctions.net/api`

// DEVELOPMENT
// const API = `http://127.0.0.1:5001/auth-testing-b4cbc/us-central1/api`


export const paystackLink = async (order) => {

    return await fetch(`${API}/create-order`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(order)
    })
    .then( res => {
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
    .then(({ url }) => {
        window.location = url
    })
}

export const calculateShipping = async (location,cartItems) => {
    return await fetch(`${API}/calculate-shipping`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({location,cartItems})
    })
    .then( async (res) => {
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    }).then( res => {
        return res
    })
    
}

export const placeOrder = async (order) => {
    return await fetch(`${API}/place-order`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(order)
    })
    .then( res => {
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
}


export const createUser = async (uid,userData) => {
    return fetch(`${API}/create-user`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({uid,userData})
    })
    .then( async res => {
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
}

export const createSigninUser = async (user) => {
    return fetch(`${API}/create-signin-user`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({user})
    })
    .then( async res => {
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
}

export const verifyPayment = async (ref,currentUser) => {

    return fetch(`${API}/success`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            ref: ref,
            currentUser: currentUser,
        })
    })
    .then( res => {
        // console.log(res)
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
    .then(({ data }) => {
        if(data.status) return data
    })

}

export const generateOrderInvoice = async (order,date) => {
    return await fetch(`${API}/generate-invoice`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({order,date})
    })
    .then( async res => {
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
    .then((data) => {
        return data
    })
}

export const generateInvoiceEmail = async (order) => {
    return await fetch(`${API}/generate-invoice-email`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(order)
    })
    .then( async res => {
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
    .then((data) => {
        return data
    })
}

export const generatePasswordReset = async (email) => {
    return await fetch(`${API}/generate-reset-link`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({email:email})
    })
    .then( res => {
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
    .then((data) => {
        return data
    })
}

export const generateEmailVerification = async (email,uid) => {
    return await fetch(`${API}/generate-email-verification`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({email:email , uid:uid})
    })
    .then( async res => {
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
    .then((data) => {
        return data
    })
}

export const verifyEmail = async (token) => {
    return await fetch(`${API}/verify-user-account`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({token})
    })
    .then( async res => {
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
    .then((data) => {
        return data
    })
}

export const sendPaymentReminder = async (order) => {

   return fetch(`${API}/send-payment-reminder`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(order)      
    })
    .then( async res => {
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
    
}
export const sendPaymentConfirmation = async (order) => {

   return fetch(`${API}/send-payment-confirmation`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(order)      
    })
    .then( async res => {
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
    
}

export const sendCustomEmail = async (email,subject,message) => {

   return fetch(`${API}/send-custom-email`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({email,subject,message})      
    })
    .then( async res => {
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
    
}
export const sendContactEmail = async (name,subject,email,message) => {

   return fetch(`${API}/send-contact-email`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({name,subject,email,message})      
    })
    .then( async res => {
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
    })
    
}

